import React from "react";
import Slider from "react-infinite-logo-slider";

const Integrations = () => {
  const integrations = [
    {
      name: "Foodora",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/Foodora_Logo_Cherry%20Pink_RGB.png",
      height: 60,
      href: "https://www.foodora.hu/?origin=www.happygastro.hu",
    },
    {
      name: "Wolt",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/wolt.png",
      height: 25,
      marginRight: 40,
      href: "https://wolt.com/hu/hun?origin=www.happygastro.hu",
    },
    {
      name: "Falatozz",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/falatozzhu_logo_szines_v2.svg",
      height: 33,
      marginRight: 18,
      href: "https://falatozz.hu/?origin=www.happygastro.hu",
    },
    {
      name: "Számlázz",
      image:
        "https://www.szamlazz.hu/wp-content/uploads/2023/09/szamlazzhu_logo-horizontal-1_color.png",
      height: 56,
      marginRight: 0,
      href: "https://www.szamlazz.hu/?origin=www.happygastro.hu",
    },
    {
      name: "Billingo",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/billingo.png",
      height: 30,
      marginRight: 20,
      href: "https://www.billingo.hu/?origin=www.happygastro.hu",
    },
    {
      name: "Querko",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/qerko.webp",
      height: 50,
      marginRight: 4,
      href: "https://www.qerko.com/hu?origin=www.happygastro.hu",
    },
    {
      name: "iBar",
      info: "Leltározás és standolás éttermeknek",
      image: "https://static.wixstatic.com/media/36b443_d327d78c2c034adcb1cdbec57d24b8a7~mv2.png/v1/fill/w_188,h_98,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ibar_smart_white_edited.png",
      href: "https://www.ibar.ai/",
      height: 40,
      filter: "invert(1)",
    },
    {
      name: "Viva payments",
      image:
        "https://mma.prnewswire.com/media/2162532/Viva_Wallet_Logo.jpg?p=twitter",
      height: 37,
      marginBottom: 5,
      href: "https://www.vivawallet.com/hu_hu?origin=www.happygastro.hu",
    },
    {
      name: "Stripe",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/stripe.png",
      height: 55,
      marginRight: 5,
      href: "https://stripe.com/en-hu?origin=www.happygastro.hu",
    },
    {
      name: "Teya",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/teya.png",
      height: 40,
      marginRight: 8,
      href: "https://www.teya.com/hu/?origin=www.happygastro.hu",
    },
    {
      name: "Hellopay",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/hellopay.png",
      height: 36,
      marginRight: 10,
      href: "https://www.hellopay.hu/?origin=www.happygastro.hu",
    },
    {
      name: "SimplePay",
      image:
        "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/simplepay.png",
      height: 30,
      marginBottom: -7,
      href: "https://simplepay.hu/?origin=www.happygastro.hu",
    },
  ];

  return (
    <div style={{ padding: "0 0 60px 0" }}>
      <h3
        className={"title"}
        style={{ whiteSpace: "nowrap", textAlign: "center" }}
      >
        Főbb integrációink
      </h3>
      <div className="integrations-slider">
        <Slider
          width="200px"
          duration={55}
          pauseOnHover={true}
          blurBorders={true}
          blurBoderColor={"#FFF"}
        >
          {integrations.map((integration, index) => (
            <Slider.Slide>
              <a
                href={integration.href}
                rel={"noreferrer"}
                target={"_blank"}
                className="integration-logo"
                key={index}
              >
                <img
                  src={integration.image}
                  alt={integration.name}
                  style={{ height: integration.height, filter: integration.filter }}
                />
              </a>
            </Slider.Slide>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Integrations;
